<template>
  <div class="image-container">
    <img src="./city.jpg" class="full-page-image" />
    <div class="avatars-grid">
      <img v-for="(avatar, index) in sortedAvatars"
           :key="index"
           :src="avatar.avatarImageUrl"
           :style="{ animationDelay: `${index * 0.1}s`, width: `${avatarSize}px`, height: `${avatarSize}px`, boxShadow: avatar.shouldJiggle ? `0 0 10px ${getHtmlColour(avatar.avatarImageUrl)}, 0 0 15px ${getHtmlColour(avatar.avatarImageUrl)}` : '' }"
           :class="['avatar-image', 'wave-animation', { 'jiggle-animation': avatar.shouldJiggle }]" />
    </div>
    <div class="logo-container">
      <img src="./nypsitlogo.png" class="logo-image" />
    </div>
    <div class="icon-container" @click="promptAvatarCount">
      <i class="fas fa-cog has-text-white"></i>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'AvatarsScreen',
  data () {
    return {
      avatars: [],
      filledAvatars: [],
      avatarSize: 70, // Default size for avatars
      minAvatars: 200 // Minimum number of avatars to display
    }
  },
  computed: {
    sortedAvatars () {
      return this.filledAvatars.slice().sort((a, b) => a.avatarImageUrl.localeCompare(b.avatarImageUrl))
    }
  },
  mounted () {
    this.calculateAvatarSize()
    const avatarsCollection = firebaseApp.firestore().collection('nypAvatars')
    avatarsCollection.onSnapshot(snapshot => {
      this.avatars = snapshot.docs.map(doc => {
        const data = doc.data()
        data.isJiggle = data.isJiggle ? data.isJiggle.toDate() : null
        data.shouldJiggle = this.shouldJiggle(data.isJiggle)
        console.log(data)
        return data
      })
      this.minAvatars = this.avatars.length
      this.fillAvatars()
    })
    window.addEventListener('resize', this.calculateAvatarSize)
    this.jiggleInterval = setInterval(this.updateJiggleStatus, 3000)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateAvatarSize)
    clearInterval(this.jiggleInterval)
  },
  methods: {
    calculateAvatarSize () {
      const containerWidth = window.innerWidth
      const containerHeight = window.innerHeight - 60 // Subtracting the height of the logo-container
      const totalAvatars = Math.max(this.minAvatars, this.avatars.length)
      const areaPerAvatar = (containerWidth * containerHeight) / totalAvatars
      this.avatarSize = Math.sqrt(areaPerAvatar) - 5 // Subtracting gap size
      this.updateAvatarSize()
    },
    updateAvatarSize () {
      document.documentElement.style.setProperty('--avatar-size', `${this.avatarSize}px`)
    },
    fillAvatars () {
      const requiredAvatars = Math.max(this.minAvatars, this.avatars.length)
      const currentCount = this.avatars.length
      if (currentCount < requiredAvatars) {
        this.filledAvatars = Array.from({ length: requiredAvatars }, (_, i) => this.avatars[i % currentCount])
      } else {
        this.filledAvatars = this.avatars.slice()
      }
      this.filledAvatars.sort(() => Math.random() - 0.5)
    },
    shouldJiggle (isJiggle) {
      if (!isJiggle) return false
      const now = new Date()
      return (now - isJiggle) <= 5000
    },
    updateJiggleStatus () {
      this.avatars.forEach(avatar => {
        avatar.shouldJiggle = this.shouldJiggle(avatar.isJiggle)
      })
    },
    promptAvatarCount () {
      const input = prompt('How many avatars on screen?')
      const number = parseInt(input, 10)
      if (isNaN(number)) {
        alert('Invalid input. Defaulting to 200.')
        this.minAvatars = 200
      } else {
        this.minAvatars = number
      }
      this.calculateAvatarSize()
      this.fillAvatars()
    },
    getHtmlColour (url) {
      let hash = 0
      for (let i = 0; i < url.length; i++) {
        hash = url.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF
        value = Math.min(255, value + 128)
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    }
  }
}
</script>

<style scoped>
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.full-page-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatars-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--avatar-size), 1fr));
  gap: 3px;
  position: absolute;
  top: 20px; /* Adjusted to provide equal margins */
  left: 0;
  width: 100%;
  height: calc(100vh - 120px); /* Adjusting height to not overlap with logo-container and provide equal margins */
  padding: 5px;
  box-sizing: border-box;
  align-content: start;
}

.avatar-image {
  object-fit: cover;
  border-radius: 3px; /* Make the avatar images slightly rounded */
  opacity: 0.3;
}

.wave-animation {
  animation: wave 5s infinite ease-in-out;
}

.jiggle-animation {
  opacity: 0.9 !important;
  border: 0px solid !important;
  animation: jiggle 0.5s infinite;
}

@keyframes wave {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
}

@keyframes jiggle {
  0%, 100% {
    transform: translateX(0) rotate(0deg);
  }
  25% {
    transform: translateX(0) rotate(-5deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(0) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.logo-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px;
  height: 60px; /* Fixed height for the logo container */
}

.logo-image {
  height: 50px;
  max-width: 100%;
}

.icon-container {
  position: absolute;
  bottom: 5px;
  left: 5px;
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
}
</style>
